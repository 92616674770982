<template>
  <pcis-steps
    :form-type="formType"
    @prev-click="onPrevClick" 
    @next-click="onNextClick"
    ref="steps"
  >
    <el-row class="form-title-bar">
      <el-col :span="24">
        <div class="tips-required">
          <span class="is-required">*</span>
          <span>{{ t("required") }}</span>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div class="form-title">
          <span>{{t('type', form.type)}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form class="step-form" :model="form" v-if="!form.authen" v-loading="loading">
          <el-card>
            <template #header>
              <h3 class="nomargin">{{ t('authentic', 'title') }}</h3>
              <p class="nomargin tips">
                {{ t('authentic', 'text') }}
              </p>
            </template>
            <el-row :gutter="20">
              <el-col :sm="12" :xs="24">
                <el-form-item required :label="t_cm('employer.label')" :class="isInvalid(v$.authenObj.companyCode.$error)">
                  <drop-down
                    v-model="form.authenObj.companyCode"
                    :placeholder="$t('common.select.placeholder')"
                    :drawer-mode="true"
                    :items="companies"
                    :filterable="true"
                  />
                  <div class="invalid-msg" v-if="v$.authenObj.companyCode.$error">{{ v$.authenObj.companyCode.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :sm="12" :xs="24">
                <el-form-item required :label="t_pe('name.eng.label')" :class="isInvalid(v$.authenObj.name.$error)">
                  <el-input :placeholder="t_pe('name.eng.placeholder')" v-model="form.authenObj.name" @focus="v$.authenObj.name.$reset()" @blur="triggerValidate($event, v$.authenObj.name, 'upper')" @keyup="handleSubmit"/>
                  <div class="invalid-msg" v-if="v$.authenObj.name.$error">{{ v$.authenObj.name.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :sm="12" :xs="24">
                <el-form-item required :label="t_pe('phone.mobile.label')" :class="isInvalid(v$.authenObj.mobile.$error)">
                  <el-input type="tel" :placeholder="t_pe('phone.mobile.placeholder')" v-model="form.authenObj.mobile" maxlength="8" @focus="v$.authenObj.mobile.$reset()" @blur="triggerValidate($event, v$.authenObj.mobile)" @keypress="handleSubmit"/>
                    <div class="invalid-msg" v-if="v$.authenObj.mobile.$error">{{ v$.authenObj.mobile.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :sm="12" :xs="24">
                <el-form-item required :label="t_pe('email.label')" :class="isInvalid(v$.authenObj.email.$error)">
                  <!-- <el-input v-model="form.email" /> -->
                  <el-input type="email" :placeholder="t_pe('email.placeholder')" v-model="form.authenObj.email" @focus="v$.authenObj.email.$reset()" @blur="triggerValidate($event, v$.authenObj.email, 'lower')" @keypress="handleSubmit"/>
                  <div class="invalid-msg" v-if="v$.authenObj.email.$error">{{ v$.authenObj.email.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-form>
        <el-form class="step-form" :model="form" v-else>
          <el-card :header="t_cm('title')">
            <el-row :gutter="20">
              <el-col :lg="16" :sm="14" :xs="24">
                <el-form-item required :label="t_cm('employer.label')">
                  <label style="padding-left:10px">{{ companyName }}</label>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="10" :xs="24">
                <el-form-item :label="t_cm('staff.label')" :class="isInvalid(v$.form.staffId.$error)">
                  <el-input :maxlength="12" v-model="form.staffId" @focus="v$.form.staffId.$reset()" @blur="triggerValidate($event, v$.form.staffId)"/>
                  <div class="invalid-msg" v-if="v$.form.staffId.$error">{{ v$.form.staffId.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>

          <el-card :header="t_pe('title')">
            <el-row :gutter="20">
              <el-col :lg="9" :sm="9" :xs="24">
                <el-form-item required :label="t_pe('name.eng.label')">
                  <label style="padding-left:10px">{{ form.name }}</label>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="8" :xs="24">
                <el-form-item :label="t_pe('name.chi')" :class="isInvalid(v$.form.nameChi.$error)">
                  <el-input v-model="form.nameChi" @focus="v$.form.nameChi.$reset()" @blur="triggerValidate($event, v$.form.nameChi)" :maxlength="6"/>
                  <div class="invalid-msg" v-if="v$.form.nameChi.$errors.length">{{ v$.form.nameChi.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
              <el-col :lg="7" :sm="7" :xs="24">
                <el-form-item required :label="t_pe('gender.label')" :class="isInvalid(v$.form.gender.$error)">
                  <drop-down
                    v-model="form.gender"
                    :items="{
                      data: tm_pe('gender.options'),
                      value: 'value',
                      label: 'label',
                    }"
                    :placeholder="$t('common.select.placeholder')"
                  />
                  <div class="invalid-msg" v-if="v$.form.gender.$error">{{ v$.form.gender.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :lg="14" :sm="14" :xs="24">
                <el-form-item required :label="t_pe('identity.label')" :class="isInvalid(v$.form.identity.$errors.length)">
                  <el-input
                    v-model="form.identity.number"
                    class="identity"
                    :maxlength="identityMaxLength"
                    @focus="v$.form.identity.$reset()" 
                    @blur="triggerCombineValidation($event, v$.form.identity.number, 'identity')"
                  >
                    <template #prepend>
                      <el-select 
                        v-model="form.identity.type" 
                        style="width: 120px"
                        @change="v$.form.identity.$reset()"
                      >
                        <el-option
                          v-for="item in tm_pe('identity.options')"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </template>
                    <template #append v-if="showChecksum">
                      <table class="checksum">
                        <tr>
                          <td>(</td>
                          <td>
                            <el-input
                              v-model="form.identity.checksum"
                              maxlength="1"
                              @focus="v$.form.identity.$reset()" 
                              @blur="triggerCombineValidation($event, v$.form.identity.checksum, 'identity')"
                            />
                          </td>
                          <td>)</td>
                        </tr>
                      </table>
                    </template>
                  </el-input>
                </el-form-item>
                <div class="invalid-msg" v-if="v$.form.identity.$errors.length">{{ v$.form.identity.$errors[0].$message }}</div>
              </el-col> 
              <el-col :lg="10" :sm="10" :xs="24">
                <el-form-item required :label="t_pe('birthday.label')" :class="isInvalid(v$.form.birthday.$error)">
                  <date-picker v-model="form.birthday" />
                  <div class="invalid-msg" v-if="v$.form.birthday.$error">{{ v$.form.birthday.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
              <!-- <el-col :lg="12" :sm="12" :xs="24">
                <el-form-item required :label="t_pe('country.label')" :class="isInvalid(v$.form.country.$error)">
                  <drop-down
                    v-model="form.country"
                    :placeholder="$t('common.select.placeholder')"
                    :items="countries"
                    :drawer-mode="true"
                    :filterable="true"
                  />
                </el-form-item>
                <div class="invalid-msg" v-if="v$.form.country.$error">{{ v$.form.country.$errors[0].$message }}</div>
              </el-col> -->
            </el-row>
            <el-row :gutter="20">
              <el-col :lg="6" :sm="6" :xs="24">
                <el-form-item :label="t_pe('phone.office.label')" :class="isInvalid(v$.form.phone.$error)">
                  <el-input type="tel" v-model="form.phone" placeholder maxlength="8" @focus="v$.form.phone.$reset()" @blur="triggerValidate($event, v$.form.phone)" />
                  <div class="invalid-msg" v-if="v$.form.phone.$error">{{ v$.form.phone.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
              <el-col :lg="6" :sm="6" :xs="24">
                <el-form-item required :label="t_pe('phone.mobile.label')" :class="isInvalid(v$.form.mobile.$error)">
                  <el-input type="tel" :placeholder="t_pe('phone.mobile.placeholder')" v-model="form.mobile" maxlength="8" @focus="v$.form.mobile.$reset()" @blur="triggerValidate($event, v$.form.mobile)" />
                    <div class="invalid-msg" v-if="v$.form.mobile.$error">{{ v$.form.mobile.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
              <el-col :lg="12" :sm="12" :xs="24">
                <el-form-item required :label="t_pe('email.label')" :class="isInvalid(v$.form.email.$error)">
                  <!-- <el-input v-model="form.email" /> -->
                  <el-input 
                    type="email" 
                    :placeholder="t_pe('email.placeholder')"
                    v-model="form.email" 
                    @focus="v$.form.email.$reset()" 
                    @blur="triggerValidate($event, v$.form.email, 'lower')"
                    :maxlength="40" 
                  />
                  <div class="placeholder">({{ t_pe('email.text') }})</div>
                  <div class="invalid-msg" v-if="v$.form.email.$error">{{ v$.form.email.$errors[0].$message }}</div>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="form.type =='N'">
              <el-row :gutter="20">
                <el-col>
                  <p class="form-label" style="margin-bottom: 0px" v-text="t_pe('address.label')" />
                  <p
                    class="form-label"
                    style="margin-top: 0px; margin-bottom: 0px"
                    v-text="t_pe('address.tips')"
                  />
                </el-col>
              </el-row>
              <el-row :gutter="20" :class="isInvalid(v$.fullAddress.$error)">
                <el-col :lg="5" :sm="5" :xs="24">
                  <el-form-item required :label="t_pe('address.address1.label')" :class="isInvalid(v$.form.address1.$error, 'address')">
                    <el-input 
                      v-model="form.address1" 
                      @focus="v$.form.address1.$reset();v$.fullAddress.$reset();" 
                      @blur="triggerCombineValidation($event, v$.form.address1, 'address')"
                      :maxlength="40"
                    />
                    <div class="placeholder">({{ t_pe('address.address1.placeholder') }})</div>
                    <div class="invalid-msg" v-if="v$.form.address1.$error">{{ v$.form.address1.$errors[0].$message }}</div>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :sm="8" :xs="24">
                  <el-form-item required :label="t_pe('address.address2.label')" :class="isInvalid(v$.form.address2.$error, 'address')">
                    <el-input
                      v-model="form.address2" 
                      @focus="v$.form.address2.$reset();v$.fullAddress.$reset();" 
                      @blur="triggerCombineValidation($event, v$.form.address2, 'address')" 
                      :maxlength="40"  
                    />
                    <div class="placeholder">({{ t_pe('address.address2.placeholder') }})</div>
                    <div class="invalid-msg" v-if="v$.form.address2.$error">{{ v$.form.address2.$errors[0].$message }}</div>
                  </el-form-item>
                </el-col>
                <el-col :lg="11" :sm="11" :xs="24">
                  <el-form-item required :label="t_pe('address.address3.label')" :class="isInvalid(v$.form.address3.$error, 'address')">
                    <el-input 
                      v-model="form.address3" 
                      @focus="v$.form.address3.$reset();v$.fullAddress.$reset();" 
                      @blur="triggerCombineValidation($event, v$.form.address3, 'address')"
                      :maxlength="40"
                    />
                    <div class="placeholder">({{ t_pe('address.address3.placeholder') }})</div>
                    <div class="invalid-msg" v-if="v$.form.address3.$error">{{ v$.form.address3.$errors[0].$message }}</div>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="invalid-msg" v-if="v$.fullAddress.$errors.length">{{ v$.fullAddress.$errors[0].$message }}</div>
              <el-row :gutter="20">
                <el-col :lg="12" :sm="12" :xs="24">
                  <el-form-item required :label="t_pe('address.district.label')" :class="isInvalid(v$.form.district.$error)">
                    <drop-down
                      v-model="form.district"
                      :placeholder="$t('common.select.placeholder')"
                      :items="districts"
                    />
                  </el-form-item>
                  <div class="invalid-msg" v-if="v$.form.district.$error">{{ v$.form.district.$errors[0].$message }}</div>
                </el-col>
                <el-col :lg="12" :sm="12" :xs="24">
                  <el-form-item required :label="t_pe('address.area.label')" :class="isInvalid(v$.form.area.$error)">
                    <drop-down
                      v-model="form.area"
                      :placeholder="$t('common.select.placeholder')"
                      :items="regions"
                    />
                  </el-form-item>
                  <div class="invalid-msg" v-if="v$.form.area.$error">{{ v$.form.area.$errors[0].$message }}</div>
                </el-col>
              </el-row>
            </div>
            
          </el-card>

          <el-card :header="t_oc(`title.${form.type}`)" >
            <div v-if="formType=='N'">
              <el-row :gutter="20">
                <el-col :lg="8" :sm="12" :xs="24">
                  <el-form-item required :label="t_oc('language.label')" :class="isInvalid(v$.form.language.$error)">
                    <drop-down
                      v-model="form.language"
                      :items="{
                        data: tm_oc('language.options'),
                        value: 'value',
                        label: 'label',
                      }"
                      :placeholder="$t('common.select.placeholder')"
                    />
                    <div class="invalid-msg" v-if="v$.form.language.$error">{{ v$.form.language.$errors[0].$message }}</div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col>
                  <div class="el-form-item is-required">
                    <label class="el-form-item__label" v-text="t_oc('photo.label')" />
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :xl="5" :lg="6" :md="7" :sm="24" :xs="24" class="content-center-middle">
                  <image-upload :class="isInvalid(v$.form.photo.$error)" v-model="form.photo" :icon-size="90" />
                  <div class="invalid-msg" v-if="v$.form.photo.$error">{{ v$.form.photo.$errors[0].$message }}</div>
                </el-col>
                <el-col :xl="19" :lg="18" :md="17" :sm="24" :xs="24">
                  <div class="photo-requirement">
                    <el-row>
                      <el-col>
                        <u>
                          <h4 v-text="t_oc('photo.requirement.label')" />
                        </u>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col
                        :xl="6"
                        :lg="8"
                        :sm="10"
                        :xs="24"
                        style="text-align: center"
                      >
                        <img src="@/assets/photo_sample.jpg" />
                      </el-col>
                      <el-col :xl="18" :lg="16" :sm="14" :xs="24">
                        <ul>
                          <li
                            v-for="item in tm_oc('photo.requirement.detail')"
                            :key="item"
                          >
                            {{ item }}
                          </li>
                        </ul>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-else>
              <el-row :gutter="20">
                <el-col :lg="8" :sm="12" :xs="24">
                  <el-form-item required :label="t_oc('label')" :class="isInvalid(v$.form.octopus.$errors.length)" >
                    <el-input
                      type="tel"
                      v-model="form.octopus.number"
                      class="identity"
                      :maxlength="9"
                      @focus="v$.form.octopus.$reset()"
                      @blur="triggerCombineValidation($event, v$.form.octopus.number, 'octopus')"
                    >
                      <template #append>
                        <table class="checksum">
                          <tr>
                            <td>(</td>
                            <td>
                              <el-input
                                type="tel"
                                v-model="form.octopus.checksum"
                                maxlength="1"
                                @focus="v$.form.octopus.$reset()"
                                @blur="triggerCombineValidation($event, v$.form.octopus.checksum, 'octopus')"
                              />
                            </td>
                            <td>)</td>
                          </tr>
                        </table>
                      </template>
                    </el-input>
                    <div class="invalid-msg" v-if="v$.form.octopus.$errors.length">{{ v$.form.octopus.$errors[0].$message }}</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-card>
         
        </el-form>
      </el-col>
    </el-row>
    <!-- <el-card>
      <el-button type="primary" @click="test" v-text="'test'" />
    </el-card> -->
  </pcis-steps>
</template>

<style scoped>
.tips-required {
  text-align: right;
  margin: 20px 10px 10px 0px;
  padding: 0px;
}

.form-title-bar{
  line-height: 0px;
}

.form-title {
  text-align: left;
  margin: 15px 20px 0px 20px;
  font-size: 16px;
  font-weight: bold;
}

.tips-required > .is-required {
  color: #f56c6c;
  margin-right: 4px;
}

/* .el-card {
  margin-bottom: 20px;
} */

div >>> .el-card__header {
  font-size: 15px;
  font-weight: bold;
}

div >>> .el-card__body {
  padding-top: 10px;
}

.el-form-item {
  display: block;
  margin-bottom: 0px;
}

div >>> .el-form-item .el-select {
  width: 100%;
}

.identity >>> .el-input-group__append {
  background-color: #ffffff;
  padding-left: 8px;
  padding-right: 15px;
}

.identity >>> .el-input > .el-input__inner {
  border: none;
  height: 38px;
  text-align: center;
}

.checksum {
  height: 38px;
  padding: 0px;
  border: none;
  border-collapse: collapse;
  border-spacing: 0px;
}

.checksum td {
  height: 32px;
  margin: 0px;
  padding: 0px;
  border: none;
}

.checksum >>> .el-input {
  width: auto;
  height: 38px;
}

.checksum >>> .el-input {
  margin: 0px;
  padding: 0px;
}

.checksum >>> .el-input .el-input__inner {
  padding: 0px;
  width: 30px;
  height: 33px;
}

div >>> .el-input.el-date-editor {
  width: 100%;
}

div >>> image-upload {
  padding-bottom: 10px;
}

div.el-col >>> .image-photo {
  margin: 20;
}

.photo-requirement {
  border: solid 1px #dcdfe6;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
}

.photo-requirement h4 {
  margin: 0px;
}

.photo-requirement img {
  width: 178px;
  height: 238px;
  object-fit: contain;
}

.photo-requirement ul {
  margin: 0px;
}

.photo-requirement ul > li {
  font-size: 14px;
  line-height: 2;
}

.form-label {
  font-size: 14px;
  color: #606266;
}

.placeholder {
  color: #606266;
  line-height: 1.5;
}

.address .placeholder {
  color: red;
}
.address .el-input {
  display: block;
}
.tips{
  font-weight: normal;
  line-height: 1.5;
}
</style>

<script>
import "@/style/common.css";
import PersonalStepForm from "@/components/PersonalStepForm.vue";
import DropDown from "@/components/DropDown.vue";
import DatePicker from "@/components/DatePicker.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import master from "@/http/apis/master";
import authentication  from "@/http/apis/personal/authentication";
import useVuelidate from '@vuelidate/core'
import * as validators from '@/libs/i18n-validators'
import application from "@/http/apis/personal/application";
import * as common from "../../../libs/common";
import * as message from "@/plugins/message.js";
import * as loading from "@/plugins/loading.js";
import constants from "@/libs/constants";

export default {
  setup () {
    return { 
      v$: useVuelidate({
        $lazy: true,
        $autoDirty: false
      }) 
    }
  },
  validations () {
    return {
      form: {
        nameChi: { 
          checkLang: validators.checkLang({lang: "C", allowNumber: false, allowSpacing: false, allowSymbol:false}),
          maxLength: validators.maxLength({ 
            limit: { num: 6 }, 
            unit: { num: constants.unit.character } 
          })
        },
        gender: { 
          required: validators.required 
        },
        // country: { 
        //   required: validators.required 
        // },
        staffId: { 
          checkLang: validators.checkLang({lang: "E", allowNumber: true, allowSpacing: false, allowSymbol:true}), 
          maxLength: validators.maxLength({
            limit: { num: 12 },
            unit: { num: constants.unit.character }
          })
        },
        phone: { 
          numeric: validators.numeric, 
          exactLength: validators.exactLength({
            limit: { num: 8 },
            unit: { num: constants.unit.digit }
          })
        },
        octopus: this.getOctopusRules(),
        language: {
          requiredIf: validators.requiredIf(this.form.type == "N")
        },
        photo: {
          requiredIf: validators.requiredIf(this.form.type == "N"),
          checkImageFormat: validators.checkImageFormat(this.form.type),
          $autoDirty: true
        },
        identity: {
          number: { 
            required: validators.required,
            alphaNumeric: validators.checkLang({lang: "E", allowNumber: true, allowSpacing: false, allowSymbol:false}),
            checkIDMinLength: validators.checkIDLength({type: "min", data: this.form.identity}),
            checkIDMaxLength: validators.checkIDLength({type: "max", data: this.form.identity}),
            verifyIdentity: validators.verifyIdentity(this.form.identity) 
          },
          checksum: {
            requiredIf: validators.requiredIf(this.form.identity.type == 0),
            alphaNumeric: validators.checkLang({lang: "E", allowNumber: true, allowSpacing: false, allowSymbol:false}),
            verifyIdentity: validators.verifyIdentity(this.form.identity) 
          }
        },
        birthday: { 
          required: validators.required,
          checkAge: validators.checkAge({type: "min", min: {year: 13, month: 0}}),
          $autoDirty: true
        },
        address1: {
          maxLength: validators.maxLength({
            limit: { num: 40 },
            unit: { num: constants.unit.character }
          }),
          checkLang: validators.checkLang({lang: "E", allowNumber: true, allowSpacing: true, allowSymbol:true}), 
        },
        address2: {
          maxLength: validators.maxLength({
            limit: { num: 40 },
            unit: { num: constants.unit.character }
          }),
          checkLang: validators.checkLang({lang: "E", allowNumber: true, allowSpacing: true, allowSymbol:true}), 
        },
        address3: {
          maxLength: validators.maxLength({
            limit: { num: 40 },
            unit: { num: constants.unit.character }
          }),
          checkLang: validators.checkLang({lang: "E", allowNumber: true, allowSpacing: true, allowSymbol:true}), 
        },
        district: { 
          requiredIf: validators.requiredIf(this.form.type == "N")
        },
        area: { 
          requiredIf: validators.requiredIf(this.form.type == "N")
        },
        email: { 
          required: validators.required,
          maxLength: validators.maxLength({
            limit: { num: 40 },
            unit: { num: constants.unit.character }
          }), 
          email: validators.email
        },
        mobile: { 
          required: validators.required, 
          numeric: validators.numeric, 
          exactLength: validators.exactLength({
            limit: { num: 8 },
            unit: { num: constants.unit.digit }
          }) 
        }
      },
      fullAddress: {
        checkAddr: validators.checkAddr(this.form.type == "N")
      },
      authenObj: {
        name:{
          required: validators.required,
          checkLang: validators.checkLang({lang: "E", allowNumber: false, allowSpacing: true, allowSymbol:false}), 
        },
        email: { 
          required: validators.required,
          maxLength: validators.maxLength({
            limit: { num: 40 },
            unit: { num: constants.unit.character }
          }), 
          email: validators.email
        },
        mobile: { 
          required: validators.required, 
          numeric: validators.numeric, 
          exactLength: validators.exactLength({
            limit: { num: 8 },
            unit: { num: constants.unit.digit }
          }) 
        },
        companyCode:{ 
          required: validators.required 
        }
      }
    }
  },
  extends: PersonalStepForm,
  name: "PersonalForm",
  components: { DropDown, DatePicker, ImageUpload },
  data() {
    return {
      districts: {},
      regions: {},
      companies: {},
      company: null,
      countries: {},
      fullAddress: this.getFullAddress(),
      loading: true
    };
  },
  created() {
    this.verified = false;
    this.loadData();
  },
  beforeRouteUpdate(to, from, next) {
    this.verified = false;
    this.loadData();
    next();
  },
  methods: {
    handleSubmit(event) {
      if(event.keyCode == 13) {
        this.onNextClick();
      }
    },
    selectFile() {
      let element = document.getElementById("upload-image");
      element.click();
    },
    async loadData() {
      let locale = this.$i18n.locale;
      try {
        let districts =  await master.getDistricts("en");
        if (districts) {
          this.districts = {
            data: districts.data,
            value: "value",
            label: "text",
          };
        }
        let regions =  await master.getRegions("en");
        if (regions) {
          this.regions = {
            data: regions.data.map((obj) => {
              obj.text = obj.text.toUpperCase();
              obj.value = obj.value.toUpperCase();
              return obj;
            }),
            value: "value",
            label: "text",
          };
        }
        let companies = await master.getCompanies(locale);
        if (companies) {
          this.companies = {
            data: companies.data,
            value: "value",
            label: "text",
          };
        }
      } catch (err) {
        message.WarningAlertWithCallback(this.$i18n.t("exceptions.failGetData"), "", {
          closable: false,
          callback: ()=> {window.location.reload()}
        })
      } finally {
        this.loading = false;
      }
      
    },
    onPrevClick(event) {
      event.goPrev();
    },
    async onNextClick(event) {
      let personal = this.$store.state.personal;
      if (this.form.authen) {
        this.v$.form.$reset();
        let formResult = await this.v$.form.$validate();
        this.v$.fullAddress.$reset();
        let addressResult = await this.v$.fullAddress.$validate();
        if (formResult  && addressResult) {
          let loadingInstance = loading.create();
          application.postFormValidate(personal.jwt, personal.form).then(() => {
            this.verified = true;
            event.goNext();
          }).catch((err) => {
            common.handlePersonalException(err)
          }).finally(() => {
            loadingInstance.close();
          })
        } else {
          let firstInvalidItem = document.querySelector(".invalid");
          let top =
            firstInvalidItem.getBoundingClientRect().top + window.pageYOffset - 20;
          window.scrollTo({ top: top });
        }
        return;
      }

      
      this.v$.authenObj.$reset(); 
      let res = await this.v$.authenObj.$validate()
      if (res) {
        let jwt = personal.jwt;
        this.loading = true;

        let tokenRes = await common.authenticateToken(common.parseJWT(jwt).token)
        if (!tokenRes) return;

        authentication.postValidate(jwt, {
          token: common.parseJWT(jwt).token,
          type: personal.form.type == "R" ? 2 : 1,
          companyCode: personal.form.authenObj.companyCode,
          name: personal.form.authenObj.name,
          mobile: personal.form.authenObj.mobile,
          email: personal.form.authenObj.email
        // }).then((res)=>{   Modify by Morton
        }).then(()=>{
          this.authen = true;
          Object.entries(this.form.authenObj).forEach(([key, value]) => {
            if(key !== "name") {
              if(this.form[key]) value = this.form[key];
            }
            this.form[key] = value;
          })
          this.$store.commit("resetAuthenObj")
          document.body.scrollIntoView();
        }).catch((err) => {
          common.handlePersonalException(err)
        }).finally(() => {
          this.loading = false;
        });
      }
      
    },
    getOctopusRules() {
      let rules = { 
        number: { 
          required: validators.required, 
          numeric: validators.numeric,
          minLength: validators.minLength({
            limit: { num: 8 }, 
            unit: { num: constants.unit.digit }
          }),
          maxLength: validators.maxLength({
            limit: { num: 9 }, 
            unit: { num: constants.unit.digit }
          }),
          verifyOctopus: validators.verifyOctopus(this.form.octopus) 
        },
        checksum: {
          required: validators.required,
          numeric: validators.numeric,
          exactLength: validators.exactLength({
            limit: { num: 1 },
            unit: { num: "digit" }
          }),
          verifyOctopus: validators.verifyOctopus(this.form.octopus) 
        }
      }
      if (this.form.type == "N") rules = {};
      return rules
    },
    async test() {
      // this.v$.fullAddress.$reset();
      // console.log(await this.v$.fullAddress.$validate());
      // common.handlePersonalSessionExpired();
      this.$store.commit("resetAuthen");
      return;
    },
    async getCompanyName(companyCode) {
      try {
        let result = await master.getCompanyByCode(this.$i18n.locale, companyCode)        
        this.company = result.data.text;
      } catch(err) {
        message.WarningAlertWithCallback(this.$i18n.t("exceptions.failGetData"), "", {
          closable: false,
          callback: ()=> {window.location.reload()}
        })
      }
      // return result.data.text
    },
    isInvalid(invalid, extraClass = "") {
      return common.isInvalid(invalid, extraClass)
    },
    triggerValidate(event, source, type) {
      common.triggerValidate(event, source, type)
    },
    triggerCombineValidation(event, source, type) {
      event.target.value = event.target.value.trim().toUpperCase();
      common.triggerInput(event.target);
      switch(type) {
        case "identity": {
          let identity = this.form.identity;
          if(!identity.number || (identity.type == 0 && !identity.checksum)) break;
          source.$touch();
          break;
        }
        case "octopus": {
          if(this.form.octopus.number && this.form.octopus.checksum) {
            source.$touch();
          }
          break;
        }
        case "address": {
          source.$touch();
          this.fullAddress = this.getFullAddress();
          this.v$.fullAddress.$reset();
          this.v$.fullAddress.$validate();
          break;
        }
      } 
    },
    getFullAddress() {
      let form = this.$store.state.personal.form;
      return `${form.address1??""}${form.address2??""}${form.address3??""}`
    }
  },
  computed: {
    authenObj() {
      return this.form.authenObj;
    },
    companyName() {
      if (!this.company) {
        let code = this.$store.state.personal.form.companyCode
        this.getCompanyName(code);
      }
      return this.company;
    },
    showChecksum() {
      let identity = this.form.identity;
      let isShow = identity.type == 0;
      if (!isShow) {
        identity.checksum = null;
      }
      return isShow;
    },
    identityMaxLength() {
      let identity = this.form.identity;
      let maxLength = identity.type == 0 ? 8 : 12;
      if (identity.number) {
        identity.number = identity.number.substr(0, maxLength);
      }
      return maxLength;
    },
    authen: {
      get: function () {
        return this.form.authen;
      },
      set: function (val) {
        this.form.authen = val;
      },
    },
    verified: {
      get: function () {
        return this.form.verified;
      },
      set: function (val) {
        this.form.verified = val;
      },
    },
  },
  beforeRouteLeave(to) {
    let stepIndex = this.$refs.steps.steps.findIndex((obj)=>{
      return obj.routeName == to.name
    })
    if (stepIndex < 2 && this.$store.state.personal.form.authen) {
      message.ConfirmDialog(
        this.$i18n.t("common.dialog.exitConfirmation.title"),
        this.$i18n.t("common.dialog.exitConfirmation.message"),
        () => {
          this.$store.commit("resetPersonalForm")
          this.$router.push(to)
        }, 
        ()=> {},
        () => {},
        {
          showClose: false
        }
      );
      return false;
    }
    // if(this.table.list.length > 0) {
    //   ConfirmDialog(
    //     this.$i18n.t("common.dialog.exitConfirmation.title"),
    //     this.$i18n.t("common.dialog.exitConfirmation.message"),
    //     () => {
    //       this.table.list = [];
    //       this.$store.commit("clearList")
    //       this.$router.push(to)
    //     }, 
    //     ()=> {},
    //     () => {},
    //     false
    //   );
    //   return false;
    // }
  }
};
</script>
