<template >
  <div :class="customClass">
    <div class="native-select" v-if="isMobile">
      <div v-if="drawerMode">
        <el-input v-model="drawerValue" :placeholder="placeholder" @focus="onFocus" />
        <el-drawer
          v-model="showDrawer"
          direction="rtl"
          size="80%"
          :show-close="false"
          :with-header="false"
          @open="onDrawerOpen"
        >
          <el-input v-model="search" :placeholder="placeholder" ref="search" />
          <el-table
            class="drawer-table"
            :data="drawerData"
            :show-header="false"
            @row-click="onRowClick"
          >
            <el-table-column :prop="items.label"></el-table-column>
          </el-table>
        </el-drawer>
      </div>
      <select v-else v-model="value" @change="onChange">
        <option :value="null" disabled v-if="showPlaceholder()">
          {{ placeholder }}
        </option>
        <option
          v-for="item in items.data"
          :key="getAttrs(item, items.value)"
          :value="getAttrs(item, items.value)"
        >
          {{ getAttrs(item, items.label) }}
        </option>
      </select>
    </div>
    <el-select
      v-else
      v-model="value"
      @change="onChange"
      :placeholder="placeholder"
      :filterable="filterable"
    >
      <el-option
        v-for="item in items.data"
        :key="getAttrs(item, items.value)"
        :value="getAttrs(item, items.value)"
        :label="getAttrs(item, items.label)"
      />
    </el-select>
  </div>
</template>

<style scoped>
.native-select select {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-color: #dcdfe6;
  padding-left: 0px 15px;
  color: #606266;
  text-indent: 15px;
  appearance: 15px;
}
.drawer-table {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}
</style>

<script>
import * as common from "@/libs/common.js";
export default {
  name: "DropDown",
  props: {
    modelValue: [String, Number, Object],
    items: {
      type: Object,
      default: () => ({
        data: [],
        value: null,
        label: null,
      }),
    },
    placeholder: {
      type: String,
      default: null,
    },
    drawerMode: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      isMobile: common.isMoblie(),
      list: this.items,
      value: null,
      search: null,
      showDrawer: false,
    };
  },
  methods: {
    getAttrs(item, key) {
      let val = item[key];
      return val == null ? item : val;
    },
    showPlaceholder() {
      let placeholder = this.placeholder;
      return !(placeholder == null || placeholder == "");
    },
    updateModelValue(val) {
      this.$emit("update:modelValue", val);
    },
    onChange() {
      this.updateModelValue(this.value);
    },
    onFocus(event) {
      event.target.blur();
      this.showDrawer = true;
    },
    onDrawerOpen() {
      this.search = null;
      this.$nextTick(() => {
        this.$refs.search.blur();
      });
    },
    onRowClick(row) {
      this.updateModelValue(row.value);
      this.value = row.value
      this.showDrawer = false;
    },
    getMsg() {
      return "<a>Hello</a>"
    }
  },
  computed: {
    drawerData() {
      return this.items.data.filter(
        (item) =>{
          if(item[this.items.label]) {
            return !this.search ||
            item[this.items.label].toLowerCase().includes(this.search.toLowerCase())
          }
        }
      );
    },
    drawerValue() {
      let value = this.value;
      if (this.items.data && value) {
        value = this.items.data.find(obj => obj.value == value).text;
      }
      return value
    }
  },
  created() {
    this.value = this.modelValue;
  }
};
</script>
