<template>
  <div class="el-input" v-if="isMobile">
    <input
      class="el-input__inner"
      type="date"
      :placeholder="placeholder"
      v-model="dateString"
      @change="onInputChange"
    />
  </div>
  <el-date-picker
    v-if="!isMobile"
    :placeholder="placeholder"
    v-model="date"
    @change="onElDatePickerChange"
  />
</template>

<script>
import * as common from '@/libs/common.js';
import moment from "moment"
export default {
  name: "DatePicker",
  props: {
    placeholder: String,
    modelValue: [Date, String]
  },
  data() {
    return {
      isMobile: common.isMoblie(),
      date: this.modelValue,
      dateString: this.modelValue2DateString(),
    }
  },
  emits: ["update:modelValue"],
  methods: {
    updateModelValue(val) {
      this.$emit("update:modelValue", val);
    },
    onInputChange(event) {
      let date = moment(event.target.value);
      this.updateModelValue(date.toDate());
    },
    onElDatePickerChange(event) {
      this.updateModelValue(event);
    },
    modelValue2DateString() {
      return moment(this.modelValue).format("YYYY-MM-DD");
    }
  }
}
</script>
