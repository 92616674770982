<template>
  <div class="image-upload content-center-middle" @click="selectFile" :style="cssVars">
    <input ref="upload" type="file" accept="image/*" style="display: none" @change="onChange" />

    <div class="btn-clear content-center-middle" @click="btnClearClick" v-if="modelValue != null">
      <el-icon>
        <!-- <font-awesome-icon :icon="clearIcon" /> -->
        <i class="bi bi-trash-fill"></i>
      </el-icon>
    </div>

    <div class="image-upload-icon">
      <slot name="icon" v-if="modelValue == null">
        <el-icon>
          <!-- <font-awesome-icon :icon="icon" /> -->
          <i class="bi bi-person-fill"></i>
        </el-icon>
      </slot>

      <div v-else class="image-upload-preview">
        <img ref="image" :src="modelValue" />
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  --border: 1px dashed #c0ccda;
}

.image-upload {
  /* width: calc((100% - 20px - 10px - 2px)); */
  /* height: calc((100% - 20px - 10px - 2px)); */
  width: 205.5px;
  height: 274px;
  margin: 10px;
  padding: 5px;
  border: var(--border);
  background-color: #fbfdff;
  border-radius: 6px;
  text-align: center;
  color: #8c939d;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.image-upload .btn-clear {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  border-right: var(--border);
  border-bottom: var(--border);
  user-select: none;
  border-top-left-radius: 6px;
}

.image-upload .btn-clear:hover {
  color: #f56c6c;
}

.image-upload-preview,
.image-upload-preview>img {
  width: 165px;
  height: 212px;
}

.image-upload-icon .el-icon {
  font-size: var(--icon-font-size);
}
</style>

<script>
import "@/style/common.css";
import Compressor from "compressorjs";
import { changeDpiDataUrl } from "changedpi";

export default {
  name: "ImageUpload",
  props: {
    modelValue: [File, Object, String],
    icon: {
      type: [String, Array],
      default: () => ["fas", "user"],
    },
    iconSize: {
      type: Number,
      default: 60,
    },
    clearIcon: {
      type: [String, Array],
      default: () => ["fas", "trash-alt"],
    },
  },
  emits: ["beforeSelect", "afterSelect", "change", "update:modelValue"],
  data() {
    return {};
  },
  methods: {
    selectFile() {
      this.$emit("beforeSelect", this);
      this.$refs.upload.click();
      this.$emit("afterSelect", this);
    },
    onChange(event) {
      let target = event.target;
      if (target.files == null || target.value == "") {
        return;
      }
      let file = target.files[0];
      let limit = 600000;
      let vm = this;
      let options = {
        width: 600,
        height: 800,
        mimeType: "image/jpeg",
        quality: vm.getCompressQuality(file),
        success(result) {
          if (result.size > limit) {
            new Compressor(result, options)
          } else {
            vm.blobToBase64(result, (result) => {
              result = changeDpiDataUrl(result, 300)
              vm.$emit("update:modelValue", result);
            });
            vm.$emit("change", vm);
          };
        },
        error(err) {
          console.log(err);
          // reject({
          //   code: "format",
          //   errMsg: vm.$i18n.t("form.exceptions.validation.image.format")
          // });
        }
      }
      new Compressor(file, options);

    },
    btnClearClick(event) {
      event.stopPropagation();
      this.$emit("update:modelValue", null);
      this.$refs.upload.value = null;
    },
    blobToBase64(blob, callback) {
      let reader = new FileReader();
      reader.onload = function(e) {
        if (callback) {
          callback(e.target.result);
        }
      };
      reader.readAsDataURL(blob);
    },
    getCompressQuality(file) {
      let limit = 600000;
      let overLimit = false;
      // eslint-disable-next-line no-unused-vars
      let quality = 0.8;
      overLimit = file.size > limit
      if (overLimit) {
        // let ratio = 1 - limit / files[0].size;
        let ratio = limit / file.size;
        quality = Math.round(ratio * 10) / 10;
      }
      return quality;
    }
  },
  computed: {
    cssVars() {
      return {
        "--icon-font-size": this.iconSize + "px",
      };
    },
  },
};
</script>
