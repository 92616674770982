<script>
import BaseStep from "@/views/personal/steps/BaseStep.vue";

let company = "company";
let personal = "personal";
let octopus = "octopus";

export default {
  extends: BaseStep,
  data() {
    return {
      form: this.$store.state.personal.form,
    };
  },
  methods: {
    t_cm(key) {
      return this.t(company, key);
    },
    t_pe(key) {
      return this.t(personal, key);
    },
    t_oc(key) {
      return this.t(octopus, key);
    },
    tm_pe(key) {
      return this.tm(personal, key);
    },
    tm_oc(key) {
      return this.tm(octopus, key);
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$store.commit("updateForm", val);
      },
      deep: true,
    },
  },
};
</script>
